<template>
    <div class="side-menu" @click="toggle" :class="expanded ? 'side-menu-expanded' : ''">
        <template v-if="!expanded">
            <b-icon-chevron-double-right/>
            <p class="menu-text">{{ $i18n.gt('MENU')}}</p>
        </template>

        <div v-if="expanded">
            <b-icon-chevron-double-left/>
            <p class="expanded-menu-text">{{ $i18n.gt('MENU')}}</p>
            <div class="d-flex flex-column">
                <b-btn @click="navigate('/pests')">
                    <img src="../../assets/icons/pest.svg">
                    {{ $i18n.gt('PEST_INSECTS_LABEL') }}
                </b-btn>
                <b-btn @click="navigate('/beneficials')">
                    <img src="../../assets/icons/wasp.svg">
                    {{ $i18n.gt('BENEFICIALS_LABEL') }}
                </b-btn>
                <b-btn @click="navigate('/symptoms')">
                    <img src="../../assets/icons/clipboard-pulse.svg">
                    {{ $i18n.gt('SYMPTOMS_LABEL') }}
                </b-btn>
                <b-btn @click="navigate('/diseases')">
                    <img src="../../assets/icons/leaf-eaten.svg" style="width: 16px;">
                    {{ $i18n.gt('DISEASES_LABEL') }}
                </b-btn>
                <b-btn variant="success" @click="navigate('/scouts/create')">
                    <img src="../../assets/icons/clipboard-check.svg">
                    {{ $i18n.gt('SCOUT_LABEL') }}
                </b-btn>
                <b-btn variant="success" @click="navigate('/applications/create')">
                    <img src="../../assets/icons/box-arrow-down-right.svg">
                    {{ $i18n.gt('APPLICATION_LABEL') }}
                </b-btn>
                <b-btn @click="navigate('/locations')">
                    <img src="../../assets/icons/house.svg">
                    {{ $i18n.gt('LOCATIONS_LABEL') }}
                </b-btn>
                <b-btn @click="navigate('/interactions')" class="small-es">
                    <img src="../../assets/icons/spray.svg" style="width: 18px;">
                    {{ $i18n.gt('PESTICIDE_INTERACTIONS_LABEL') }}
                </b-btn>
                <b-btn @click="navigate('/pesticides')">
                    <img src="../../assets/icons/spray.svg" style="width: 18px;">
                    {{ $i18n.gt('PESTICIDES_LABEL') }}
                </b-btn>
                <b-btn @click="navigate('/tasks')">
                    <b-icon-card-checklist style="width: 24px;"/>
                    {{ $i18n.gt('TASKS_LABEL') }}
                </b-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavBar',
    data() {
        return {
            expanded: false,
        }
    },
    computed: {

    },
    methods: {
        toggle() {
            this.expanded = !this.expanded
        },
        navigate(route) {
            this.$router.push(route)
        },

    }
}

</script>

<style lang="scss">

@import "/sass/variables_mixins.scss";

div.side-menu.side-menu-expanded .btn,.expanded-menu-text  {
    animation-duration: 1s;
    animation-name: slidein;
}

@keyframes slidein {
    from {
        //margin-left: -100%;
        transform:translateX(-200%);
    }

    to {
        //margin-left: 0%;
        transform:translateX(0%);
    }
}

div.side-menu {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top:100px;
    left: 0;
    margin-top: 3em;
    padding-bottom: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0px 10px 10px 0px ;
    border-top: 2px solid #3C964E;
    border-bottom: 2px solid #3C964E;
    border-right: 2px solid #3C964E;
    background:$light-green;
    width: 50px;
    transition: all .3s ease-in-out;
    height:560px;
    max-height:560px;

    &:hover {
        background-color: $light-green--hover;

    }
    &:not(.side-menu-expanded):hover {
        /*
        top:80px;
        padding-top:30px;
        padding-bottom:30px;
        height:600px;
        max-height:600px;
        */
         .menu-text {
             letter-spacing: 2px;
         }
    }

    button.btn {
       // max-height: 0;
        //overflow: hidden;
       // transition:all .2s;
    }

    &.side-menu-expanded {
        //transition-delay:.5s;
        width: 250px;
        justify-content: space-between;
        .expanded-menu-text {
            //margin-top: 1em;
            color: darken($green,10%);
            text-align: center;
            font-size: 24px;
            line-height: 1;
            font-weight: 400;
        }
        .bi-chevron-double-left {
            position: absolute;
            right: 1rem;
            top: 1.5rem;
            align-content: center;
            width: 20px;
            height: 20px;
            color: darken($green,10%);
        }
        button.btn {
            &:not(:first-child) {
                margin-top: .5em;
            }
        }
    }

    .bi-chevron-double-right {
        position: absolute;
        top: 1.5em;
        align-content: center;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        color: #076A1A;
    }
    .menu-text {
        display: flex;
        height: 90%;
        justify-content: center;
        writing-mode: vertical-rl;
        color: darken($green,10%);
        text-align: center;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        transition: all .3s;
    }
    .btn {
        padding:5px 10px;
    }
}

@media (max-width: 767px) {
    div.side-menu{display:none;}
}

</style>
