<template>
    <div class="tasks-edit narrow-form">
        <h1 class="page-heading">Edit Task</h1>

        <b-row>
            <b-col cols="12">

                <data-form :field-model="taskModel"
                           :load-model-instance="loadModelInstance"
                           :on-submit-handler="updateTask"
                           :field-groups="fieldGroups"
                           cancel-route="/tasks"
                           bottom-submit-button bottom-cancel-button/>

            </b-col>
        </b-row>

    </div>
</template>

<script>

import * as _ from 'lodash'
import Utils from '@/providers/Utils'
import Task from "../models/Task"

export default {
    name: 'TasksEdit',
    props: {
        taskId: String,
    },
    data() {
        return {
            modelInstance: {},
            taskModel: Task,
            fieldGroups: [
                ['name', 'name_es', 'textSections'],
            ]
        }
    },
    computed: {},
    methods: {
        async loadModelInstance() {
            const item = await this.$api.getItem(this, `tasks`, this.taskId)
            this.modelInstance = item
            return item
        },
        async updateTask(modelInstance, vform) {
            Utils.initializeModelFields(modelInstance, this.fieldGroups[0])
            await Utils.uploadModelTextSectionImages(modelInstance)

            await this.$api.updateItem(this, `tasks`, this.taskId, modelInstance)

            // reload task list in store (in case name changed)
            await Task.loadTasks()

            this.$router.push(`/tasks`)
            this.$toasted.success(this.$i18n.gt('TASK_UPDATED_SUCCESS'))
        }

    }
}
</script>

<style lang="scss">
.tasks-edit {
  label.custom-file-label {
    z-index: 0;
  }

  img.location-image {
    max-height: 200px;
  }
}
</style>
