<template>
    <div class="diseases card-list">
        <b-row class="my-5">
            <b-col class="d-flex justify-content-between">
                <h1>{{ $i18n.gt('DISEASES_LABEL') }}</h1>
                <span class="btn-add--top">
                    <b-btn v-if="isAdmin"
                           @click="navigate('/diseases/create')" variant="success">
                        <img src="../../assets/icons/clipboard-pulse.svg">
                        Add Disease
                        <b-icon-plus-circle-fill/>
                    </b-btn>
                </span>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="4" v-for="item in items" :key="item.id">
                <b-card no-body class="mb-3">
                    <b-card-header>
                        <b-row>
                            <b-col class="card-list__name">
                                {{ $i18n.getDiseaseName(item.id) }}
                            </b-col>
                            <b-col class="text-right">
                                <b-button v-b-toggle="`collapse-${item.id}`" size="sm" variant="light">
                                    <b-icon-caret-down/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-header>
                    <b-collapse :id="`collapse-${item.id}`" class="mt-2">
                        <b-card-body>
                            <text-section-view v-for="(section, index) in item.textSections"
                                               :key="index"
                                               :section="section"/>
                            <div v-if="item.symptoms" class="disease__symptoms">
                                <strong>{{ $i18n.gt('SYMPTOMS_LABEL') }}:</strong>
                                <div v-for="(symptom, i) in getSymptoms(item)" class="ml-4" :key="i">
                                    <router-link :to="`/symptoms/view/${symptom.split('/')[1]}`">
                                        {{ $i18n.getSymptomName(symptom) }}
                                    </router-link>
                                </div>
                            </div>
                        </b-card-body>
                        <b-card-footer class="text-right" v-if="isAdmin || item.factSheetUrl">
                            <template v-if="item.factSheetUrl">
                                <b-btn size="sm" variant="secondary" class="btn-icon mr-3"
                                       v-b-popover.top.hover="$i18n.gt('FACT_SHEET_TOOLTIP')"
                                       :href="item.factSheetUrl" target="_blank">
                                    <b-icon-link45deg/>
                                </b-btn>
                            </template>
                            <template v-if="isAdmin">
                                <b-btn size="sm" variant="danger" class="btn-icon mr-3"
                                       v-b-popover.top.hover="$i18n.gt('DELETE_TOOLTIP')"
                                       @click="deleteDisease(item)">
                                    <b-icon-x-square-fill/>
                                </b-btn>
                                <b-btn size="sm" variant="success"
                                       v-b-popover.top.hover="$i18n.gt('EDIT_TOOLTIP')"
                                       @click="navigate(`/diseases/${item.id}/edit`)" >
                                    <b-icon-pencil-fill/>
                                </b-btn>
                            </template>
                        </b-card-footer>
                    </b-collapse>
                </b-card>
            </b-col>
        </b-row>

        <b-row class="mt-3">
            <b-col class="d-flex justify-content-end">
                <b-btn v-if="isAdmin"
                       @click="navigate('/diseases/create')" variant="success">
                    <img src="../../assets/icons/clipboard-pulse.svg">
                    Add Disease
                    <b-icon-plus-circle-fill/>
                </b-btn>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import * as _ from 'lodash'
import Disease from "../models/Disease"

export default {
    name: 'Diseases',
    props: {
        diseaseId: String,
    },
    data() {
        return {
            items: [],
        }
    },
    computed: {
        isAdmin() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.admin
            } else return !!this.$store.state.currentDbUser.admin
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        getSymptoms(item) {
            if (item.symptoms) {
                return item.symptoms.split(',')
            }
        },
        async getItems() {
            this.items = _.sortBy(this.$store.state.diseases, 'name')
        },
        async deleteDisease(item) {
            if (confirm('Permanently delete this Disease?')) {
                await this.$api.deleteItem(this, 'diseases', item)
                this.$toasted.success(this.$i18n.gt('DISEASE_DELETED_SUCCESS'))

                // reload beneficial list in store to include new name
                await Disease.loadDiseases()

                this.getItems()
            }
        },
    },
    async mounted() {
        await this.getItems()
        // if a disease id is passed, un-collapse it by default
        if (this.diseaseId) {
            await this.$root.$emit('bv::toggle::collapse', `collapse-${this.diseaseId}`)
            document.getElementById(`collapse-${this.diseaseId}`).parentElement.scrollIntoView()
            this.$router.replace('/diseases')
        }
    },
}
</script>

