<template>
    <div class="locations">
        <b-row class="row-heading">
            <b-col class="d-flex justify-content-between locations-list-top">
                <h1>{{ $i18n.gt('LOCATIONS_HEADING') }}</h1>
                <span>
                    <b-btn @click="navigate('/locations/create')" variant="success">
                        <b-icon-house/> {{$i18n.gt('LOCATIONS_CREATE_HEADING')}} <b-icon-plus-circle-fill/>
                    </b-btn>
                </span>
            </b-col>
        </b-row>

        <template v-if="$mq === 'lg' || $mq === 'xl'">
            <b-table v-if="loaded && !noLocations"
                     striped
                     ref="locTable"
                     :items="items"
                     :fields="locationFields">
                <template #cell(name)="data">
                    <router-link :to="`/locations/${data.item.id}/view`">
                        {{ data.item.name }}
                    </router-link>
                </template>
                <template #cell(card)="data">
                    <b-form-select :options="data.item.cardOptions" v-model="data.item.selectedCard"
                                   @change="updateCard(data)"></b-form-select>
                </template>
                <template #cell(recentScout)="data">
                    <div v-html="data.item.recentScout"></div>
                </template>
                <template #cell(recentApplication)="data">
                    <div v-html="data.item.recentApplication"></div>
                </template>
                <template #cell(actions)="data">
                    <router-link class="btn btn-secondary btn-sm mr-2 btn-icon"
                                 :class="data.item.selectedCard === -1 ? ' disabled' : ''"
                                 :to="`/locations/${data.item.id}/cards/${data.item.selectedCard}/view`"
                                 v-b-popover.top.hover="$i18n.gt('VIEW_QR_TOOLTIP')">
                        <b-icon-eye-fill/>
                    </router-link>
                    <router-link class="btn btn-secondary btn-sm mr-2 btn-icon"
                                 :class="data.item.selectedCard === -1 ? ' disabled' : ''"
                                 :to="`/locations/${data.item.id}/cards/${data.item.selectedCard}/edit`"
                                 v-b-popover.top.hover="$i18n.gt('EDIT_TOOLTIP')">
                        <b-icon-pencil/>
                    </router-link>
                    <b-btn class="btn btn-secondary btn-sm mr-2 btn-icon"
                                 :class="data.item.selectedCard === -1 ? ' disabled' : ''"
                                 @click="deleteCard(data)"
                                 v-b-popover.top.hover="$i18n.gt('DELETE_TOOLTIP')">
                        <b-icon-x-square-fill/>
                    </b-btn>
                    <router-link class="btn btn-secondary btn-sm mr-2 btn-icon"
                                 :class="data.item.selectedCard === -1 ? ' disabled' : ''"
                                 :to="`/locations/${data.item.id}/cards/${data.item.selectedCard}/scouts/create`"
                                 v-b-popover.top.hover="$i18n.gt('SCOUT_TOOLTIP')">
                        <b-icon-check-circle/>
                    </router-link>
                    <router-link class="btn btn-secondary btn-sm mr-2 btn-icon"
                                 :class="data.item.selectedCard === -1 ? ' disabled' : ''"
                                 :to="`/locations/${data.item.id}/cards/${data.item.selectedCard}/applications/create`"
                                 v-b-popover.top.hover="$i18n.gt('APPLICATION_TOOLTIP')">
                        <b-icon-bar-chart-fill/>
                    </router-link>
                    <router-link class="btn btn-secondary btn-sm mr-2 btn-icon"
                                 :class="data.item.selectedCard === -1 ? ' disabled' : ''"
                                 :to="`/locations/${data.item.id}/cards/${data.item.selectedCard}/chart`"
                                 v-b-popover.top.hover="$i18n.gt('CHART_TOOLTIP')">
                        <b-icon-graph-up/>
                    </router-link>
                </template>
            </b-table>
            <b-card v-if="loaded && noLocations">
                <em>{{ $i18n.gt('LOCATIONS_NO_LOCATIONS') }}</em>
            </b-card>
            <b-spinner v-if="!loaded"/>
        </template>

        <!-- mobile layout -->
        <template v-else>
            <spinner-until :condition="loaded">
                <b-card v-for="(loc, i) in items" :key="loc.key" class="mb-3" :ref="`mobile-card-${i}`">
                    <b-row class="justify-content-between px-3 mb-2">
                        <h2 class="mobile-loc-name">{{ loc.name }}</h2>
                        <b-btn variant="secondary" size="sm" @click="$router.push(`/locations/${loc.id}/view`)">
                            <b-icon-eye-fill/>
                        </b-btn>
                    </b-row>
                    <b-form-select :options="loc.cardOptions" v-model="loc.selectedCard"
                                   @change="updateCard({item: loc, index: i, key: loc.key}, i)" class="mb-3 mobile-select">
                    </b-form-select>
                    <label class="mobile-label">{{$i18n.gt('LOCATIONS_TABLE_HEADING_RECENT_SCOUT')}}</label>
                    <b-row class="mb-4">
                        <b-col>
                            <template v-if="loc.scoutDate">
                                <strong class="col col-4">{{ loc.scoutDate }}</strong>
                                <div class="col col-8" v-html="loc.recentScout"></div>
                            </template>
                            <em v-else>{{$i18n.gt('LOCATIONS_TABLE_NO_APPLICATION')}}</em>
                        </b-col>
                    </b-row>
                    <label class="mobile-label">{{$i18n.gt('LOCATIONS_TABLE_HEADING_RECENT_APP')}}</label>
                    <b-row class="mb-4">
                        <b-col>
                            <template v-if="loc.applicationDate">
                                <strong class="col-4">{{ loc.applicationDate }}</strong>
                                <div class="col-8" v-html="loc.recentApplication"></div>
                            </template>
                            <em v-else>{{$i18n.gt('LOCATIONS_TABLE_NO_APPLICATION')}}</em>
                        </b-col>
                    </b-row>
                    <b-select class="mobile-location__actions" :options="actionsOptions"
                              v-if="loc.selectedCard !== -1"
                              v-model="selectedAction" @change="onActionSelect(loc)"/>
                </b-card>
            </spinner-until>
            <b-card v-if="loaded && noLocations">
                <em>{{ $i18n.gt('LOCATIONS_NO_LOCATIONS') }}</em>
            </b-card>
        </template>

        <b-row class="mt-3">
            <b-col class="d-flex" :class="$mq === 'xl' || $mq === 'lg' ? 'justify-content-end' : 'justify-content-center'">
                <b-btn @click="navigate('/locations/create')" variant="success">
                    <b-icon-house/>
                    {{$i18n.gt('LOCATIONS_CREATE_HEADING')}}
                    <b-icon-plus-circle-fill/>
                </b-btn>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import moment from "moment";
import Vue from "vue";

export default {
    name: 'Locations',
    data() {
        return {
            locationFields: [
                {key: 'name', label: this.$i18n.gt('LOCATIONS_TABLE_HEADING_NAME')},
                {key: 'card', label: this.$i18n.gt('LOCATIONS_TABLE_HEADING_CARD')},
                {key: 'recentScout', label: this.$i18n.gt('LOCATIONS_TABLE_HEADING_RECENT_SCOUT')},
                {key: 'scoutDate', label: this.$i18n.gt('LOCATIONS_TABLE_HEADING_SCOUT_DATE')},
                {key: 'recentApplication', label: this.$i18n.gt('LOCATIONS_TABLE_HEADING_RECENT_APP')},
                {key: 'applicationDate', label: this.$i18n.gt('LOCATIONS_TABLE_HEADING_APP_DATE')},
                {key: 'actions', label: this.$i18n.gt('LOCATIONS_TABLE_HEADING_CARD_ACTIONS')}
            ],
            items: [],
            loaded: false,
            noLocations: false,
            actionsOptions: [
                { text: this.$i18n.gt('LOCATIONS_TABLE_HEADING_CARD_ACTIONS'), value: null },
                { text: this.$i18n.gt('VIEW_QR_TOOLTIP'), value: 'view' },
                { text: this.$i18n.gt('EDIT_TOOLTIP'), value: 'edit' },
                { text: this.$i18n.gt('DELETE_TOOLTIP'), value: 'delete' },
                { text: this.$i18n.gt('SCOUT_TOOLTIP'), value: 'scout' },
                { text: this.$i18n.gt('APPLICATION_TOOLTIP'), value: 'application' },
                { text: this.$i18n.gt('CHART_TOOLTIP'), value: 'chart' },
            ],
            selectedAction: null,
        }
    },
    computed: {
        userId() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.id
            } else return this.$store.state.currentUser.uid
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        onActionSelect(loc) {
            switch(this.selectedAction){
                case 'view':
                    this.navigate(`/locations/${loc.id}/cards/${loc.selectedCard}/view`)
                    break
                case 'edit':
                    this.navigate(`/locations/${loc.id}/cards/${loc.selectedCard}/edit`)
                    break
                case 'scout':
                    this.navigate(`/locations/${loc.id}/cards/${loc.selectedCard}/scouts/create`)
                    break
                case 'application':
                    this.navigate(`/locations/${loc.id}/cards/${loc.selectedCard}/applications/create`)
                    break
                case 'qr':
                    this.navigate(`/locations/${loc.id}/cards/${loc.selectedCard}/view`)
                    break
                case 'chart':
                    this.navigate(`/locations/${loc.id}/cards/${loc.selectedCard}/chart`)
                    break
            }
        },
        updateCard(data, index = null) {
            const ind = index ? index : data.index
            if (this.items[ind].selectedCard !== 'new') {
                const card = _.find(this.items[ind].cards, (card) => {
                    return card.id === this.items[ind].selectedCard
                })
                if (card) {
                    Vue.set(this.items, ind, {
                        ...this.items[ind],
                        recentScout: card.recentScout,
                        scoutDate: card.scoutDate,
                        recentApplication: card.recentApplication,
                        applicationDate: card.applicationDate
                    })
                }
            } else {
                this.$router.push(`/locations/${this.items[data.index].id}/cards/create`)
            }
        },
        async deleteCard(data) {
            if (await this.$bvModal.msgBoxConfirm(this.$i18n.gt('LOCATIONS_CARD_CONFIRM_DELETE'), {okVariant: 'danger', okTitle: this.$i18n.gt('DELETE_TOOLTIP')})) {
                let item = data.item
                await this.$api.deleteItem(this, `users/${this.userId}/locations/${item.id}/cards/`, {id: item.selectedCard})
                _.remove(item.cards, (card) => {
                    return card.id === item.selectedCard
                })
                _.remove(data.item.cardOptions, (card) => {
                    return card.value === item.selectedCard
                })
                item.selectedCard = item.cardOptions[0].value
                this.updateCard(data)
                const items = _.cloneDeep(this.items)
                this.items = []
                this.items = items
                this.$toasted.success(this.$i18n.gt('LOCATIONS_CARD_DELETED_SUCCESS'))
            }
        },
        parseCardOptions(cards) {
            let options = []
            cards.forEach((card) => {
                options.push({value: card.id, text: card.name})
            })
            if (!options.length) {
                options.push({value: -1, text: this.$i18n.gt('NO_CARDS_OPTION')}) // does not dynamically update translation (requires refresh)
            }
            options = _.sortBy(options, ['text'])
            options.push({value: 'new', text: this.$i18n.gt('CARDS_CREATE_LABEL')}) // same here
            return options
        },
        parseRecentScout(scout) {
            let result = ''
            const pestLookup = {}
            this.$store.state.pestsUnnested.forEach((pest) => {
                pestLookup[pest.id] = pest.name
            })
            const benLookup = {}
            this.$store.state.beneficials.forEach((ben) => {
                benLookup[ben.id] = ben.name
            })
            if (scout.pests) {
                _.forEach(scout.pests, (pest) => {
                    result += pestLookup[pest.ref.split('/')[3]]
                    result += ` - ${pest.count}<br>`
                })
            }
            if (scout.beneficials) {
                _.forEach(scout.beneficials, (ben) => {
                    result += benLookup[ben.ref.split('/')[1]]
                    result += ` - ${ben.count}<br>`
                })
            }
            if (result === '' && scout) {
                result = '<em>Invalid Scout Information</em>'
            }
            return result
        },
        parseRecentApplication(application) {
            let result = ''
            if (application.beneficialApplied) {
                result += _.find(this.$store.state.beneficials, (ben) => {
                    return ben.id === application.beneficialApplied.split('/')[1]
                }).name
                result += ' - ' + application.beneficialQuantity
            }
            if (application.pesticide) {
                result += _.find(this.$store.state.pesticides, (pes) => {
                    return pes.id === application.pesticide.split('/')[1]
                }).name
                result += ' - ' + application.pesticideQuantity
            }
            if (result === '' && application) {
                result = '<em>Invalid Application Information</em>'
            }
            return result
        },
        getItems() {
            return this.$api.getAllItems(this, `users/${this.userId}/locations`).then((locations) => {
                if (locations.length === 0) {
                    this.noLocations = true
                    this.loaded = true
                }
                locations.forEach((location, locIndex) => {
                    // add key so that we can force template updates when switching cards in mobile view
                    location.key = location.id + '-'
                    this.$api.getAllItems(this, `users/${this.userId}/locations/${location.id}/cards`).then((cards) => {
                        if (cards.length === 0 && locations.length === locIndex + 1) {
                            this.loaded = true
                        }
                        locations[locIndex].cards = cards
                        locations[locIndex].cardOptions = this.parseCardOptions(cards)
                        locations[locIndex].selectedCard = locations[locIndex].cardOptions[0].value
                        cards.forEach((card, cardIndex) => {
                            this.$api.getAllItems(this, `users/${this.userId}/locations/${location.id}/cards/${card.id}/scouts`).then((scouts) => {
                                if (scouts.length) {
                                    scouts = _.sortBy(scouts, ['date'])
                                    const lastIndex = scouts.length - 1
                                    cards[cardIndex].recentScout = this.parseRecentScout(scouts[lastIndex])
                                    if (scouts[lastIndex].date) {
                                        cards[cardIndex].scoutDate = moment(scouts[lastIndex].date).format('M/D/YY')
                                    }
                                    if (card.id === locations[locIndex].selectedCard) {
                                        locations[locIndex].recentScout = cards[cardIndex].recentScout
                                        locations[locIndex].scoutDate = cards[cardIndex].scoutDate
                                    }
                                }
                            })
                            this.$api.getAllItems(this, `users/${this.userId}/locations/${location.id}/cards/${card.id}/applications`).then((applications) => {
                                if (applications.length) {
                                    applications = _.sortBy(applications, ['date'])
                                    const lastIndex = applications.length - 1
                                    cards[cardIndex].recentApplication = this.parseRecentApplication(applications[lastIndex])
                                    if (applications[lastIndex].date) {
                                        cards[cardIndex].applicationDate = moment(applications[lastIndex].date).format('M/D/YY')
                                    }
                                    if (card.id === locations[locIndex].selectedCard) {
                                        locations[locIndex].recentApplication = cards[cardIndex].recentApplication
                                        locations[locIndex].applicationDate = cards[cardIndex].applicationDate
                                    }
                                }
                                if (cardIndex === cards.length - 1 && locIndex === locations.length - 1) {
                                    this.loaded = true
                                }
                            })
                        })
                    })
                })
                this.items = _.sortBy(locations, [loc => loc.name.toLowerCase()])
            })
        },
        updateLang() {
            if (this.$refs.locTable) {
                this.locationFields = [
                    {key: 'name', label: this.$i18n.gt('LOCATIONS_TABLE_HEADING_NAME')},
                    {key: 'card', label: this.$i18n.gt('LOCATIONS_TABLE_HEADING_CARD')},
                    {key: 'recentScout', label: this.$i18n.gt('LOCATIONS_TABLE_HEADING_RECENT_SCOUT')},
                    {key: 'scoutDate', label: this.$i18n.gt('LOCATIONS_TABLE_HEADING_SCOUT_DATE')},
                    {key: 'recentApplication', label: this.$i18n.gt('LOCATIONS_TABLE_HEADING_RECENT_APP')},
                    {key: 'applicationDate', label: this.$i18n.gt('LOCATIONS_TABLE_HEADING_APP_DATE')},
                    {key: 'actions', label: this.$i18n.gt('LOCATIONS_TABLE_HEADING_ACTIONS')}
                ],
                    this.$refs.locTable.refresh()
            }
        }
    },
    mounted() {
        this.getItems()
        window.addEventListener('language-changed', this.updateLang)
    },
    beforeDestroy() {
        window.removeEventListener('language-changed', this.updateLang)
    }
}
</script>

<style lang="scss">
@import "/sass/variables_mixins";

.locations {

    .card-body {
        em {
            padding: 0 5px;
        }
    }

    .delete-loc {
        color: #BE4040;
        cursor: pointer;
        &:hover {
            color: #cb6666;
        }
    }

    tbody td:last-child {
        @media (max-width: 1200px) {
            width: 190px;
        }

        .btn {
            //&:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
            margin-bottom: 10px;
            //}
        }

        .btn {
            border: none;

            &:hover {
                border-color: #3C964E;
                background-color: #3C964E;
                color: #fff;
            }
        }
    }
    .mobile-loc-name {
        color: #076A1A;
    }
    @media (max-width:719px) {
        .locations-list-top {
            flex-direction: column;
            align-items: center;
        }
    }
    @media (max-width:767px) {
        .card-body {
            padding: 1em;
        }
    }
}

.icon-qr img {
    filter: brightness(0) invert(1);
}


</style>
