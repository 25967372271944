<template>
    <div class="data-form-input-text-sections">
        <draggable v-model="sections">
            <div v-for="(section,i) in sections" :key="i">
                <b-card no-body class="mb-3">
                    <b-card-header>
                        <b-row>
                            <b-col>
                                <span class="text-section__heading">{{ section.heading }}</span>
                            </b-col>
                            <b-col class="text-right">
                                <b-button v-b-toggle="`collapse-${i}`" size="sm" variant="light">
                                    <b-icon-caret-down/>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-header>
                    <b-collapse :id="`collapse-${i}`" class="mt-2" v-model="section.expanded">
                        <b-card-body>
                            <b-form-group :label="$i18n.gt('TEXTSECTIONS_HEADING_FIELD')">
                                <b-form-input type="text"
                                              v-model="section.heading"
                                              @input="updateSections()"/>
                            </b-form-group>
                            <b-form-group :label="$i18n.gt('TEXTSECTIONS_HEADING_ES_FIELD')">
                                <b-form-input type="text"
                                              v-model="section.heading_es"
                                              @input="updateSections()"/>
                            </b-form-group>
                            <b-form-group :label="$i18n.gt('TEXTSECTIONS_TEXT_FIELD')">
                                <vue-trix v-model="section.text"
                                          @input="updateSections()"
                                          rows="3"/>
                            </b-form-group>
                            <b-form-group :label="$i18n.gt('TEXTSECTIONS_TEXT_ES_FIELD')">
                                <vue-trix v-model="section.text_es"
                                          @input="updateSections()"
                                          rows="3"/>
                            </b-form-group>

                            <b-form-group :label="$i18n.gt('TEXTSECTIONS_IMAGE_FIELD')">
                                <div class="image-preview" v-if="!!getPreviewImageUrl(section)">
                                    <img :src="getPreviewImageUrl(section)"/>
                                </div>

                                <!-- NB: this will throw a warning if the image is an existing URL rather than a File -->
                                <b-file accept="image/jpeg, image/png, image/gif, image/heic, image/heif"
                                        @input="imageSelected($event, section)"
                                        v-model="sections[i].image"/>
                            </b-form-group>
                        </b-card-body>
                        <b-card-footer class="text-right">
                            <b-button variant="danger" size="sm" class="btn-icon"
                                      @click="deleteSection(section)">
                                <b-icon-x/>
                                Delete Section
                            </b-button>

                        </b-card-footer>
                    </b-collapse>
                </b-card>
            </div>
        </draggable>

        <div class="text-right">
            <b-btn variant="success" @click="addSection()">
                Add Section
                <b-icon-plus-circle-fill/>
            </b-btn>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import draggable from 'vuedraggable'
import * as _ from 'lodash'

export default Vue.extend({
    name: 'DataFormInputTextSections',
    components: {draggable},
    props: {
        value: {
            required: true
        },
        state: Boolean,
    },
    data() {
        return {
            sections: [],
        }
    },
    computed: {
        localValue: {
            get() {
                return this.sections
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    },
    methods: {
        addSection() {
            this.sections.push({
                heading: '',
                heading_es: '',
                text: '',
                text_es: '',
                image: null,
                expanded: true,
            })
        },
        deleteSection(section) {
            if (confirm('Permanently delete this text section?')) {
                this.sections = _.without(this.sections, section)
                this.updateSections()
            }
        },
        updateSections() {
            this.localValue = this.sections
        },
        imageSelected(event, section) {
            console.log('selected', event)
        },
        getPreviewImageUrl(section) {
            if (section.image instanceof File) {
                return URL.createObjectURL(section.image)
            } else {
                return section.image
            }
        },
    },
    watch: {
        // load initial value
        value: {
            immediate: true,
            handler: function (newVal, oldVal) {
                if (newVal) { // this will be undefined on a new modelInstance
                    this.sections = newVal
                }
            }
        }
    }

})
</script>

<style lang="scss">
@import "/sass/variables_mixins";

.data-form-input-text-sections {
    input, .form-control, select, textarea, .trix-content {
        background-color: $light-neutral-bg!important;
    }
}
.text-section__heading {
    font-size: 1.25rem;
    text-transform: uppercase;
}


</style>
