<template>
    <div class="home-user mt-3 mb-5 mt-md-5">
        <h1 class="my-3 text-center">{{ $i18n.gt('DASHBOARD_HEADING') }}</h1>
        <div class="home-user__dashboard" style="margin: 0 auto;">
            <b-row class="mb-3">
                <b-col>
                    <b-btn @click="navigate('/pests')" class="mb-3 mb-md-0">
                        <img src="../../assets/icons/pest.svg">
                        {{ $i18n.gt('PEST_INSECTS_LABEL') }}
                    </b-btn>
                    <b-btn @click="navigate('beneficials')" class="ml-md-3">
                        <img src="../../assets/icons/wasp.svg">
                        {{ $i18n.gt('BENEFICIALS_LABEL') }}
                    </b-btn>
                </b-col>
            </b-row>
            <b-row class="mb-3">
                <b-col>
                    <b-btn @click="navigate('/symptoms')" class="mb-3 mb-md-0">
                        <img src="../../assets/icons/clipboard-pulse.svg">
                        {{ $i18n.gt('SYMPTOMS_LABEL') }}
                    </b-btn>
                    <b-btn @click="navigate('/diseases')" class="ml-md-3">
                        <img src="../../assets/icons/leaf-eaten.svg" style="width: 16px;">
                        {{ $i18n.gt('DISEASES_LABEL') }}
                    </b-btn>
                </b-col>
            </b-row>
            <b-row class="mb-3">
                <b-col>
                    <b-btn @click="navigate('/scouts/create')" variant="success" class="mb-3 mb-md-0">
                        <img src="../../assets/icons/clipboard-check.svg">
                        {{ $i18n.gt('SCOUT_LABEL') }}
                    </b-btn>
                    <b-btn @click="navigate('/applications/create')" variant="success" class="ml-md-3">
                        <img src="../../assets/icons/box-arrow-down-right.svg">
                        {{ $i18n.gt('APPLICATION_LABEL') }}
                    </b-btn>
                </b-col>
            </b-row>
            <b-row class="mb-3">
                <b-col>
                    <b-btn @click="navigate('/locations')" class="mb-3 mb-md-0">
                        <img src="../../assets/icons/house.svg">
                        {{ $i18n.gt('LOCATIONS_LABEL') }}
                    </b-btn>
                    <b-btn @click="navigate('/interactions')" class="ml-md-3 small-es">
                        <img src="../../assets/icons/spray.svg" style="width: 18px;">
                        {{ $i18n.gt('PESTICIDE_INTERACTIONS_LABEL') }}
                    </b-btn>
                </b-col>
            </b-row>
            <b-row class="mb-3">
                <b-col>
                    <b-btn @click="navigate('/pesticides')" class="mb-3 mb-md-0">
                        <img src="../../assets/icons/spray.svg" style="width: 18px;">
                        {{ $i18n.gt('PESTICIDES_LABEL') }}
                    </b-btn>
                    <b-btn @click="navigate('/tasks')" class="ml-md-3">
                        <b-icon-card-checklist style="margin-right: 6px; width: 24px;"/>
                        {{ $i18n.gt('TASKS_LABEL') }}
                    </b-btn>
                </b-col>
            </b-row>

        </div>
    </div>
</template>

<script>

export default {
    name: 'HomeUser',
    data() {
        return {}
    },
    computed: {
        userId() {
            return this.$store.state.currentUser.uid
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
    }
}
</script>

<style lang="scss">
.home-user {
  .row > .col {
    @media (min-width: 768px) {
      display: flex;
      justify-content: center;
    }

    .btn {
      width: 252px;
      height: 44px;
      padding: 8px 20px;
      border-radius: 3px;

      img {
        width: 24px;
        margin-right: 6px;
      }
    }
  }
}

.home-user__dashboard {
  padding-left: 5px;
  padding-right: 5px;
  max-width: 600px;
}

@media (max-width: 767px) {
  .home-user__dashboard {
    max-width: 252px;
  }
}


</style>
