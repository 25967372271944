import Vue from 'vue'
import VueMoment from 'vue-moment'
import Toasted from 'vue-toasted'
import App from './App'
import router from '@/router'
import { store } from './store'
import {StatusBar, Style} from '@capacitor/status-bar';

import Utils from './providers/Utils'

import {
    AlertPlugin,
    BadgePlugin,
    ButtonPlugin,
    CardPlugin,
    CollapsePlugin,
    FormCheckboxPlugin,
    FormDatepickerPlugin,
    FormFilePlugin,
    FormGroupPlugin,
    FormInputPlugin,
    FormPlugin,
    FormRadioPlugin,
    FormSelectPlugin,
    FormTextareaPlugin,
    IconsPlugin,
    InputGroupPlugin,
    LayoutPlugin,
    ListGroupPlugin,
    ModalPlugin,
    NavbarPlugin,
    PaginationPlugin,
    PopoverPlugin,
    ProgressPlugin,
    SidebarPlugin,
    SpinnerPlugin,
    TablePlugin,
    TabsPlugin,
    BOverlay
} from 'bootstrap-vue'
import HighchartsVue from 'highcharts-vue'

import api from '@/providers/Api'
import i18n from '@/providers/i18n'
import VueMq from 'vue-mq'
import {Capacitor} from "@capacitor/core";
import { ScreenOrientation } from "@capacitor/screen-orientation";

Vue.use(Toasted, { duration: 5000 })

Vue.use(VueMoment)

Vue.use(LayoutPlugin)
Vue.use(NavbarPlugin)
Vue.use(SidebarPlugin)
Vue.use(ButtonPlugin)
Vue.use(TablePlugin)
Vue.use(PaginationPlugin)
Vue.use(SpinnerPlugin)
Vue.use(FormPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(InputGroupPlugin)
Vue.use(FormFilePlugin)
Vue.use(CollapsePlugin)
Vue.use(CardPlugin)
Vue.use(PopoverPlugin)
Vue.use(TabsPlugin)
Vue.use(AlertPlugin)
Vue.use(BadgePlugin)
Vue.use(ModalPlugin)
Vue.use(ListGroupPlugin)
Vue.use(IconsPlugin)
Vue.use(ProgressPlugin)

Vue.use(HighchartsVue)

Vue.config.productionTip = false

Vue.component('b-overlay', BOverlay)

Utils.registerComponents()
Utils.addCustomMixin('api')
Utils.addCustomMixin('i18n')

let app

Vue.use(VueMq, {
    breakpoints: { // default breakpoints - customize this
        sm: 540,
        md: 768,
        lg: 1024,
        xl: 1140,
    },
    defaultBreakpoint: 'sm' // customize this for SSR
})

app = new Vue({
    router,
    store,
    api,
    i18n,
    render: h => h(App),
}).$mount('#app')

document.documentElement.lang = localStorage.getItem('language') ?? 'en'
document.body.classList.add(localStorage.getItem('language') ?? '')
if (Capacitor.isNativePlatform()) {
    await ScreenOrientation.lock({orientation: 'portrait'})
    // await ScreenOrientation.unlock()
    const setStatusBarStyleDark = async () => {
        await StatusBar.setStyle({ style: Style.Light });
    };
    const setStatusBarColor = async () => {
        await StatusBar.setBackgroundColor({ color: '#ffa725' });
    };
    await setStatusBarStyleDark()
    if (Capacitor.getPlatform() === 'android') {
        await setStatusBarColor()
    }
}

