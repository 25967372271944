<template>
    <div class="nav-bar">
        <b-row v-if="!!maskUser.id" class="masking">
            {{ $i18n.gt('MASKING_AS') }}
            <template v-if="maskUser.firstName && maskUser.lastName">
                {{ `${maskUser.firstName} ${maskUser.lastName}` }}
            </template>
            <template v-else>
                {{ maskUser.email }}
            </template>
            <b-icon-person-x-fill class="ml-3" @click="unmask" v-b-popover.bottom.hover="$i18n.gt('STOP_MASKING')"/>
        </b-row>
        <b-navbar>
            <b-navbar-brand @click="navigate('/')">
                <img src="../../assets/images/logo.png">
            </b-navbar-brand>

            <!-- toggle mobile nav (only shows on smaller screens) -->
            <b-navbar-nav v-if="$mq !== 'xl'" @click="toggleMobileNav()">
                <b-icon-list v-if="!showMobileNav"/>
                <b-icon-x v-if="showMobileNav"/>
            </b-navbar-nav>

            <!-- language selection -->
            <b-row class="lang" @click="toggleLang" v-if="$mq === 'xl'">
                <img v-if="currentLang === 'en'" class="flag" src="../../assets/icons/spanish.svg">
                <img v-else class="flag" src="../../assets/icons/english.svg">
                <span class="lang-text ml-2">{{otherLang}}</span>
            </b-row>

            <b-navbar-nav class="ml-auto nav-items" v-if="$mq === 'xl'">
                <!-- public actions -->
                <template v-if="!loggedIn">
                    <!-- signup is only available on mobile, but the route can still be accessed in dev
                    <b-nav-item @click="navigate('/signup')">{{$i18n.gt('SIGNUP')}}</b-nav-item>-->
                    <b-nav-item @click="navigate('/tutorial')" class="no-border">{{$i18n.gt('TUTORIAL')}}</b-nav-item>
                    <b-nav-item @click="navigate('/about')" class="no-border">{{$i18n.gt('ABOUT')}}</b-nav-item>
                    <b-nav-item @click="navigate('/contact')" class="no-border">{{$i18n.gt('CONTACT')}}</b-nav-item>
                    <b-nav-item @click="navigate('/login')" class="no-border">{{$i18n.gt('LOGIN')}}</b-nav-item>
                </template>

                <!-- logged in actions -->
                <template v-else>
                    <!-- admin dropdown -->
                    <b-nav-item-dropdown v-if="isAdmin" :text="$i18n.gt('ADMIN')" right class="pr-3 no-border">
                        <b-dropdown-item @click="navigate('/users')">{{$i18n.gt('USER_MANAGEMENT')}}</b-dropdown-item>
                        <b-dropdown-item @click="navigate('/database')">{{$i18n.gt('DATABASE_MANAGEMENT')}}</b-dropdown-item>
                    </b-nav-item-dropdown>

<!--                    <b-nav-item @click="sync"><b-icon-arrow-repeat/></b-nav-item>-->
                    <b-nav-item @click="navigate('/contact')"><b-icon-envelope/></b-nav-item>
                    <b-nav-item @click="navigate('/tutorial')"><b-icon-question/></b-nav-item>

                    <!-- user dropdown -->
                    <b-nav-item-dropdown text="User" class="no-chevron" right>
                        <template #button-content>
                            <b-icon-person-fill/>
                        </template>
                        <b-dropdown-item @click="navigate('/profile')" :disabled="!!maskUser.email">{{username}}</b-dropdown-item>
                        <b-dropdown-item @click="logoutUser" :disabled="!!maskUser.email">{{$i18n.gt('LOGOUT')}}</b-dropdown-item>
                        <b-dropdown-item @click="unmask" v-if="!!maskUser.email">{{$i18n.gt('STOP_MASKING')}}</b-dropdown-item>
                    </b-nav-item-dropdown>
                </template>
            </b-navbar-nav>
        </b-navbar>

        <!-- mobile nav sidebar -->
        <b-sidebar
            v-if="$mq !=='xl'"
            v-model="showMobileNav"
            class="mobile-sidebar"
            no-header
            shadow
            backdrop
            >
            <div class="sidebar-content">
                <template v-if="loggedIn">
                    <b-btn @click="navigate('/pests')">
                        <img src="../../assets/icons/pest.svg">
                        {{ $i18n.gt('PEST_INSECTS_LABEL') }}
                    </b-btn>
                    <b-btn @click="navigate('/beneficials')">
                        <img src="../../assets/icons/wasp.svg">
                        {{ $i18n.gt('BENEFICIALS_LABEL') }}
                    </b-btn>
                    <b-btn @click="navigate('/symptoms')">
                        <img src="../../assets/icons/clipboard-pulse.svg">
                        {{ $i18n.gt('SYMPTOMS_LABEL') }}
                    </b-btn>
                    <b-btn @click="navigate('/diseases')">
                        <img src="../../assets/icons/leaf-eaten.svg" style="width: 16px;">
                        {{ $i18n.gt('DISEASES_LABEL') }}
                    </b-btn>
                    <b-btn variant="success" @click="navigate('/scouts/create')">
                        <img src="../../assets/icons/clipboard-check.svg">
                        {{ $i18n.gt('SCOUT_LABEL') }}
                    </b-btn>
                    <b-btn variant="success" @click="navigate('/applications/create')">
                        <img src="../../assets/icons/box-arrow-down-right.svg">
                        {{ $i18n.gt('APPLICATION_LABEL') }}
                    </b-btn>
                    <b-btn @click="navigate('/locations')">
                        <img src="../../assets/icons/house.svg">
                        {{ $i18n.gt('LOCATIONS_LABEL') }}
                    </b-btn>
                    <b-btn @click="navigate('/interactions')" class="small-es">
                        <img src="../../assets/icons/spray.svg" style="width: 18px;">
                        {{ $i18n.gt('PESTICIDE_INTERACTIONS_LABEL') }}
                    </b-btn>
                    <b-btn @click="navigate('/pesticides')">
                        <img src="../../assets/icons/spray.svg" style="width: 18px;">
                        {{ $i18n.gt('PESTICIDES_LABEL') }}
                    </b-btn>
                    <b-btn @click="navigate('/tasks')">
                        <b-icon-card-checklist style="width: 20px;"/>
                        {{ $i18n.gt('TASKS_LABEL') }}
                    </b-btn>
                    <hr>
                </template>
                <div class="user-buttons">
                    <b-btn @click="toggleLang">
                        <img v-if="currentLang === 'en'" src="../../assets/icons/spanish.svg">
                        <img v-else class="flag" src="../../assets/icons/english.svg">
                        <span class="lang-text ml-2">{{otherLang}}</span>
                    </b-btn>
                    <template v-if="!loggedIn">
                        <!-- signup is only available on mobile, but the route can still be accessed in dev
                        <b-btn @click="navigate('/signup')">{{$i18n.gt('SIGNUP')}}</b-btn>-->
                        <b-btn @click="navigate('/tutorial')">{{$i18n.gt('TUTORIAL')}}</b-btn>
                        <b-btn @click="navigate('/about')">{{$i18n.gt('ABOUT')}}</b-btn>
                        <b-btn @click="navigate('/contact')">{{$i18n.gt('CONTACT')}}</b-btn>
                        <b-btn @click="navigate('/login')">{{$i18n.gt('LOGIN')}}</b-btn>
                    </template>
                    <template v-else>
<!--                        <b-btn @click="sync"><b-icon-arrow-repeat class="mr-2"/>{{ $i18n.gt('SYNC_LABEL') }}</b-btn>-->
                        <b-btn @click="navigate('/contact')"><b-icon-envelope class="mr-2"/>{{ $i18n.gt('CONTACT_LABEL') }}</b-btn>
                        <b-btn @click="navigate('/tutorial')"><b-icon-question-circle-fill class="mr-2"/>{{ $i18n.gt('HELP_LABEL') }}</b-btn>
                        <b-btn @click="navigate('/profile')"><b-icon-person-circle class="mr-2"/>{{ $i18n.gt('PROFILE_LABEL') }}</b-btn>
                        <b-btn @click="logoutUser">{{ $i18n.gt('LOGOUT') }}</b-btn>
                    </template>
                </div>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
import { BSidebar } from 'bootstrap-vue'
export default {
    name: 'NavBar',
    components: { BSidebar },
    data() {
        return {
            showMobileNav: false
        }
    },
    computed: {
        maskUser() {
            return this.$store.state.maskUser
        },
        loggedIn () {
            return !!this.$store.state.currentUser.uid;
        },
        username () {
            const user = this.maskUser.email ? this.maskUser : this.$store.state.currentDbUser
            const email = user.email ?? ''
            let name = null
            if (user.firstName && user.lastName) {
                user.name = user.firstName + ' ' + user.lastName
            }
            return user.name ? user.name : email.substring(0, email.lastIndexOf("@"));
        },
        otherLang () {
            return this.currentLang === 'en' ? 'Español' : 'English'
        },
        currentLang() {
            return this.$store.state.currentLang
        },
        isAdmin() {
            if (this.maskUser.email) {
                return this.maskUser.admin
            } else return !!this.$store.state.currentDbUser.admin
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        logoutUser () {
            this.$api.logoutUser(this)
        },
        toggleLang () {
            this.$i18n.switchLanguage()
        },
        sync () {
            console.log('sync')
        },
        toggleMobileNav() {
            this.showMobileNav = !this.showMobileNav
        },
        unmask() {
            this.$store.state.maskUser = {}
            sessionStorage.removeItem('maskUser')
            this.$router.push('/')
        },
    }
}

</script>

<style lang="scss">

@import '/sass/variables_mixins';

div.nav-bar {
    border-bottom-style: solid;
    border-bottom-width: 4px;
    border-bottom-color: #e2e4e2;

    .navbar-brand {
        cursor: pointer;
    }
    .b-icon {
        width: 24px;
        height: 24px;

        &.bi-list, &.bi-x {
            color: #3C964E;
            cursor: pointer;
            margin-right: -2px;
            /*
            position: absolute;
            right: 3vw;
            top: 2vh;
             */
        }
    }
    .no-chevron {
        .dropdown-toggle::after {
            content: none;
        }
    }
    .nav-items > .nav-item > a.nav-link.dropdown-toggle {
            //line-height: 32px;
    }

    .lang {
        display: flex;
        justify-content: center;
        align-content: center;
        cursor: pointer;
        box-sizing: border-box;
        position: absolute;
        //left: calc(50% - 67px);
        left:50%;
        transform:translateX(-50%);
        transition:all .3s;
        top: 0px;
        width: 134px;
        height: 42px;
        background-color: #D6EDCE;
        //background-color: lighten($light-green,5%);

        border-width: 0px 2px 2px 2px;
        border-style: solid;
        border-color: #3C964E;
        border-radius: 0px 0px 5px 5px;

        .lang-text {
            font-weight: 700;
            font-size: 16px;
            text-align: center;
            color: darken($green,10%);
        }
        .flag {
            width: 24px;
            height: 24px;
        }
        &:hover {
            width:154px;
            background-color: $light-green--hover;
        }
    }

    .nav-items {
        >.nav-item {

            &:not(.no-border) {
                > a.nav-link {
                    background-color: $light-neutral-bg;
                    border: solid 2px #ddd;
                    border-radius: 50%;
                    transition: all .2s;
                    &:hover, &:focus, &:active {
                        border-color:$green;
                        color:#fff!important;
                        background-color: $green;
                    }
                }
            }
            > a.nav-link {
                font-weight: 700;
                font-size: 14px;
                text-align: center;
                margin-left: .5rem;
            }
        }
    }
}

.navbar-nav {

    .nav-link {
        color: #465046!important;
    }

    .dropdown-item:hover, .dropdown-item:focus {
        background-color: #3C964E;
        color:#fff;
    }

}

.navbar-expand {
    justify-content: space-between;
}

@media (max-width:1023px) {
    .navbar-brand {
        max-width:240px;
        img {
            max-width:100%;
        }
    }
    .b-sidebar-outer {
       // position: fixed;
        //top: 0;
        //left: 0;
        //width: 80vw;
        //z-index: 10;
       // height: 100vh;

        .sidebar-content {
            overflow-y: scroll;
            -webkit-overflow-scrolling:touch; // mobile safari
            height: 100vh;
            background-color: #D6EDCE;
            padding: calc(env(safe-area-inset-top, 0) + 1.5em)
                calc(env(safe-area-inset-right, 0) + 1.5em)
                calc(env(safe-area-inset-bottom, 0) + 1.5em)
                calc(env(safe-area-inset-left, 0) + 1.5em);

            button {
                width: 100%;
                margin: .5em 0;
            }
            hr {
                width: 84px;
                height: 3px;
                border-radius: 4px;
                background: #076A1A;
            }
            .user-buttons {
                .b-dropdown {
                    width: 100%;
                }
                button {
                    width: 100%;
                    //background: #E8F2E4;
                    background: lighten($light-green,8%);
                    border-radius: 3px;
                    border: 2px solid #076A1A;
                    color: #076A1A;
                }
            }
        }
    }
}

@media (min-width:1024px) {
    .navbar-brand {
        max-width:280px;
        transition: opacity .3s;
        &:hover {
            opacity:.5;
        }
    }

}

.masking {
    width: 100%;
    margin: 0 0;
    background-color: #004B23;
    color: white;
    justify-content: center;
    height: 2.5em;
    align-content: center;

    svg.bi-person-x-fill {
        cursor: pointer;
    }
}
header.header {
    height: auto;
}
</style>
