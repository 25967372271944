<template>
    <div class="applications-edit">
        <spinner-until :condition="cardLoaded">
            <h1 class="mt-4">{{ title }}</h1>
            <hr>
            <b-row>
                <b-col cols="12" md="8">

                    <b-card class="mb-3">
                        <!-- enter date, select cards -->
                        <b-row>
                            <b-col>
                                <b-form-group :label="$i18n.gt('APPLICATION_DATE_FIELD')">
                                    <input type="date" class="date-select"
                                           v-model="currentApplication.date">
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group v-if="!applicationId"
                                              :disabled="applicationStep === 2"
                                              :label="`${$i18n.gt('APPLICATION_CARDS_APPLIED_FIELD')} - ${currentApplication.cardsApplied.length} selected`"
                                              :description="isNative ? '' : $i18n.gt('APPLICATION_CARDS_APPLIED_DESCRIPTION')">
                                    <b-form-select v-model="currentApplication.cardsApplied" :options="locationCardOptions" multiple/>
                                </b-form-group>
                            </b-col>
                            <div class="form-tip mb-3 mx-3" v-if="currentApplication.cardsApplied.length > 1">{{ $i18n.gt('APPLICATION_INPUT_TIP_CARDS') }}</div>
                        </b-row>
                        <template v-if="!applicationId">
                            <b-btn variant="success" v-if="applicationStep === 1" @click="confirmSelection" class="float-right">
                                {{ $i18n.gt('APPLICATION_CONFIRM_CARD')}}
                            </b-btn>
                            <b-btn variant="danger" v-if="applicationStep === 2" @click="removeSelection" class="float-right">
                                {{ $i18n.gt('APPLICATION_CHANGE_CARD')}}
                            </b-btn>
                        </template>
                    </b-card>

                    <b-card v-if="applicationStep === 2">
                        <!-- select pest, disease -->
                        <b-row>
                            <b-col>
                                <h5>{{ $i18n.gt('APPLICATION_CHOOSE_TARGET') }}</h5>
                                <div class="form-tip mb-3">{{ $i18n.gt('APPLICATION_INPUT_TIP_TARGET') }}</div>
                            </b-col>
                        </b-row>
                        <b-row class="mb-5 mb-lg-3">
                            <b-col cols="12" md="6">
                                <div class="input-frame">
                                    <legend class="col-form-label">{{ $i18n.gt('APPLICATION_LABEL_PEST') }}</legend>
                                    <b-form-checkbox-group v-model="pest" :options="cardsPestOptions"/>
                                    <b-form-select v-if="pest.includes('OTHER')"
                                                   v-model="otherPest"
                                                   :options="otherPestOptions"/>
                                    <div v-if="disease.length > 0" class="disable-input">
                                        <strong>{{ $i18n.gt('APPLICATION_TARGETED_DISEASES') }}</strong>
                                        <b-btn variant="primary" @click="disease = []">{{ $i18n.gt('APPLICATION_CLEAR_DISEASE') }}</b-btn>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="12" md="6">
                                <div class="input-frame">
                                    <legend class="col-form-label">{{ $i18n.gt('APPLICATION_LABEL_DISEASE') }}</legend>
                                    <b-form-checkbox-group v-model="disease" :options="cardsDiseaseOptions"/>
                                    <b-form-select v-if="disease.includes('OTHER')"
                                                   v-model="otherDisease"
                                                   :options="otherDiseaseOptions"/>
                                    <div v-if="pest.length > 0" class="disable-input">
                                        <strong>{{ $i18n.gt('APPLICATION_TARGETED_PESTS') }}</strong>
                                        <b-btn variant="primary" @click="pest = []">{{ $i18n.gt('APPLICATION_CLEAR_PEST') }}</b-btn>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>

                        <!-- tip: select only one beneficial or pest -->
                        <b-row class="mb-3">
                            <b-col>
                                <div class="form-tip mb-0">{{ $i18n.gt('APPLICATION_INPUT_TIP_BENEFICIAL') }}</div>
                            </b-col>
                        </b-row>

                        <!-- select beneficial/pest, tasks -->
                        <b-row class="mb-3">
                            <b-col cols="12" md="6">
                                <div class="input-frame">
                                    <b-form-group :label="$i18n.gt('APPLICATION_BENEFICIAL_FIELD')">
                                        <b-form-select v-model="currentApplication.beneficialApplied"
                                                       :options="beneficialOptions"/>
                                    </b-form-group>
                                    <b-form-group :label="$i18n.gt('APPLICATION_BENEFICIAL_QUANTITY_FIELD')">
                                        <b-form-input type="text" v-model="currentApplication.beneficialQuantity"/>
                                    </b-form-group>
                                    <div v-if="currentApplication.pesticide || currentApplication.pesticideQuantity" class="disable-input">
                                        <strong>{{ $i18n.gt('APPLICATION_APPLYING_PESTICIDE') }}</strong>
                                        <b-btn variant="primary" @click="currentApplication.pesticide = null; currentApplication.pesticideQuantity = null">{{ $i18n.gt('APPLICATION_CLEAR_PESTICIDE') }}</b-btn>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="12" md="6">
                                <div class="input-frame">
                                    <b-form-group :label="$i18n.gt('APPLICATION_PESTICIDE_FIELD')">
                                        <b-form-select v-model="currentApplication.pesticide"
                                                       :options="pesticideOptions"/>
                                    </b-form-group>
                                    <b-form-group :label="$i18n.gt('APPLICATION_PESTICIDE_QUANTITY_FIELD')">
                                        <b-form-input type="text" v-model="currentApplication.pesticideQuantity"/>
                                    </b-form-group>
                                    <div v-if="currentApplication.beneficialApplied || currentApplication.beneficialQuantity" class="disable-input">
                                        <strong>{{ $i18n.gt('APPLICATION_APPLYING_BENEFICIAL') }}</strong>
                                        <b-btn variant="primary" @click="currentApplication.beneficialApplied = null; currentApplication.beneficialQuantity = null">{{ $i18n.gt('APPLICATION_CLEAR_BENEFICIAL') }}</b-btn>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="mb-3">
                            <b-col cols="12">
                                <div class="input-frame">
                                    <b-form-group :label="$i18n.gt('APPLICATION_TASKS_FIELD')">
                                        <b-form-checkbox-group v-model="currentApplication.tasks"
                                                           :options="taskOptions"/>
                                    </b-form-group>

                                </div>
                            </b-col>
                        </b-row>
                        <!-- action buttons -->
                        <div class="bottom-buttons">
                            <b-btn variant="danger" @click="cancel">
                                <b-icon-x-circle-fill/>
                                {{ $i18n.gt('LABEL_CANCEL') }}
                            </b-btn>
                            <b-btn variant="success" @click="submitApplication(false)" :disabled="valid">
                                <b-icon-cloud-arrow-up-fill/>
                                {{ $i18n.gt('LABEL_SAVE') }}
                            </b-btn>
                            <!-- only show this in create mode -->
                            <b-btn variant="outline-primary"
                                   :disabled="valid"
                                   v-if="!applicationId"
                                   @click="submitApplication(true)">
                                <b-icon-cloud-arrow-up-fill/>
                                {{ $i18n.gt('LABEL_SAVE_AND_NEW') }}
                                <b-icon-plus-circle-fill/>
                            </b-btn>
                        </div>

                    </b-card>
                </b-col>

                <!-- list previous applications -->
                <b-col class="mt-3" v-if="applicationStep === 2">
                    <h5>{{ $i18n.gt('APPLICATION_PREVIOUS_APPLICATIONS') }} ({{ previousApplications.length }})</h5>
                    <b-table v-if="!noPreviousApplications" class="table-striped latest-report"
                             :items="previousApplications"/>
                    <span v-else>{{ $i18n.gt('APPLICATION_NO_PREVIOUS') }}</span>
                </b-col>
            </b-row>
        </spinner-until>
    </div>
</template>

<script>

import * as _ from 'lodash'
import i18n from '@/providers/i18n'
import Utils from '@/providers/Utils'
import Location from '@/models/Location'
import Card from '@/models/Card'
import Pest from '@/models/Pest'
import Disease from '@/models/Disease'
import Task from '@/models/Task'
import Beneficial from '@/models/Beneficial'
import Pesticide from '@/models/Pesticide'
import moment from "moment/moment";
import {Capacitor} from "@capacitor/core";

export default {
    name: 'ApplicationsEdit',
    props: {
        locId: String,
        cardId: String,
        applicationId: {type: String, default: null},
    },
    data() {
        const otherPestOptions = Pest.buildPestOptionsUnnested()
        const otherDiseaseOptions = Disease.buildDiseaseOptions()
        const beneficialOptions = Beneficial.buildBeneficialOptions()
        const pesticideOptions = Pesticide.buildPesticideOptions()
        const taskOptions = Task.buildTaskOptions()

        return {
            cardLoaded: true,
            currentApplication: {
                cardsApplied: [],
            },

            // step 1 select cards, set 2 fill out form
            applicationStep: 1,
            selectedCards: [],

            // track pest/disease internally - these need to be mapped to/from pestTarget, diseaseTarget on load/save
            pest: [],
            otherPest: null,
            disease: [],
            otherDisease: null,

            beneficial: null,
            pesticide: null,
            tasks: [],

            locationCardOptions: [],
            cardsPestOptions: [],
            otherPestOptions: otherPestOptions,
            cardsDiseaseOptions: [],
            otherDiseaseOptions: otherDiseaseOptions,
            beneficialOptions: beneficialOptions,
            pesticideOptions: pesticideOptions,
            taskOptions: taskOptions,

            previousApplications: [],
            noPreviousApplications: false,
            lastScout: null,
        }
    },
    computed: {
        userId() {
            if (this.$store.state.maskUser.id) {
                return this.$store.state.maskUser.id
            } else return this.$store.state.currentUser.uid
        },
        title() {
            return this.$i18n.gt(this.applicationId ? 'APPLICATION_EDIT' : 'APPLICATION_CREATE')
        },
        isNative() {
            return Capacitor.isNativePlatform()
        },
        valid() {
            return this.currentApplication.cardsApplied.length < 1
                || !this.currentApplication.date
                || (this.pest.length < 1 && this.disease.length < 1)
                || (!this.currentApplication.beneficialApplied && !this.currentApplication.pesticide)
                || ((this.currentApplication.beneficialApplied && !this.currentApplication.beneficialQuantity)
                    || (this.currentApplication.pesticide && !this.currentApplication.pesticideQuantity))
        }
    },
    methods: {
        navigate(route) {
            this.$router.push(route)
        },
        getApplication(locId, cardId, applicationId) {
            return this.$api.getItem(this, `users/${this.userId}/locations/${locId}/cards/${cardId}/applications/`, applicationId)
        },
        async confirmSelection() {
            if (this.currentApplication.cardsApplied.length > 0) {
                this.loadPreviousApplications()
                this.selectedCards = await Promise.all(this.currentApplication.cardsApplied.map((card) => {
                    const segments = card.split('/')
                    return this.$api.getItem(this, `users/${segments[1]}/locations/${segments[3]}/cards/`, segments[5])
                }))
                this.cardsPestOptions = this.loadCardsTargetOptions('pest')
                this.cardsDiseaseOptions = this.loadCardsTargetOptions('disease')
                this.applicationStep = 2
            } else {
                this.$toasted.error(this.$i18n.gt('APPLICATION_ERROR_SELECT_CARD'))
            }
        },
        async removeSelection() {
            if (await this.$bvModal.msgBoxConfirm(this.$i18n.gt('APPLICATION_SWITCH_CONFIRM'))) {
                this.applicationStep = 1

                this.pest = null
                this.otherPest = null
                this.disease = null
                this.otherDisease = null
                this.currentApplication.pestTarget = null
                this.currentApplication.diseaseTarget = null
                this.currentApplication.notes = ''
                this.currentApplication.beneficialApplied = null
                this.currentApplication.beneficialQuantity = null
                this.currentApplication.pesticide = null
                this.currentApplication.pesticideQuantity = null
                this.currentApplication.tasks = []
            }
        },
        async loadPreviousApplications() {
            let apps = []
            await Promise.all(this.currentApplication.cardsApplied.map(async (card) => {
                const response = await this.$api.getAllItems(this, `${card}/applications`)
                apps = apps.concat(_.map(response, (app) => {
                    return {...app, card: card}
                }))
            }))
            apps = _.reverse(_.sortBy(apps, (app) => {
                return app.date
            }))
            const items = []
            let cards = []
            _.forEach(this.locationCardOptions, (locationCard) => {
                cards = cards.concat(locationCard.options)
            })
            // add an item for each beneficial OR pesticide application (possibly at the same time)
            for (let app of apps) {
                if (app.beneficialApplied) {
                    items.push({
                        date: app.date,
                        card: _.find(cards, (card) => {
                            return card.value === app.card
                        }).text,
                        applied: this.$i18n.getBeneficialName(app.beneficialApplied),
                        amount: app.beneficialQuantity,
                    })
                }
                if (app.pesticide) {
                    items.push({
                        date: app.date,
                        card: _.find(cards, (card) => {
                            return card.value === app.card
                        }).text,
                        applied: this.$i18n.getPesticideName(app.pesticide),
                        amount: app.pesticideQuantity,
                    })
                }
            }
            this.previousApplications = items
        },
        loadCardsTargetOptions(targetType) {
            let targets = []
            _.forEach(this.selectedCards, (card) => {
                if (targetType === 'pest' && card.pests) {
                    targets = targets.concat(card.pests)
                } else if (targetType !== 'pest' && card.diseases) {
                    targets = targets.concat(card.diseases)
                }
            })
            targets = _.uniq(targets)
            let options = []
            if (targets.length > 0) {
                if (targetType === 'pest') {
                    options = _.map(targets, (path) => {
                        return {value: path, text: i18n.getPestName(path)}
                    })
                } else {
                    options = _.map(targets, (path) => {
                        return {value: path, text: i18n.getDiseaseName(path)}
                    })
                }
                options = _.sortBy(options, 'text')
            }
            options.push({value: 'OTHER', text: this.$i18n.gt('LABEL_OPTION_OTHER')})
            return options
        },
        createApplication() {
            return {
                date: moment().format('YYYY-MM-DD'),
                pestTarget: null,
                diseaseTarget: null,
                notes: '',
                beneficialApplied: null,
                beneficialQuantity: null,
                pesticide: null,
                pesticideQuantity: null,
                tasks: [],
                cardsApplied: [],
            }
        },
        // TODO: fix saving to multiple cards. don't need to save 'cardsApplied' property
        async submitApplication(doAnother = false) {
            this.cardLoaded = false
            this.updatePestTarget()
            this.updateDiseaseTarget()
            const cardsApplied = this.currentApplication.cardsApplied
            delete this.currentApplication.cardsApplied
            const segments = cardsApplied[0].split('/')
            if (!this.applicationId) { // creating new record
                await cardsApplied.forEach(async (card) => {
                    const itemResponse = await this.$api.createItem(this, `${card}/applications`, this.currentApplication)
                })
                this.$toasted.success(this.$i18n.gt('APPLICATION_CREATED_SUCCESS'))
                if (doAnother) {
                    window.location.reload()
                } else {
                    this.$router.push(`/locations/${segments[3]}/cards/${segments[5]}/chart`)
                }
            } else { // updating existing record
                await this.$api.updateItem(this, `users/${this.userId}/locations/${this.locId}/cards/${this.cardId}/applications`, this.applicationId, this.currentApplication)
                this.$toasted.success(this.$i18n.gt('APPLICATION_UPDATED_SUCCESS'))
                this.$router.push(`/locations/${this.locId}/cards/${this.cardId}/chart`)
            }
        },
        cancel() {
            if (this.applicationId) {
                this.$router.push(`/locations/${this.locId}/cards/${this.cardId}/chart`)
            } else if (confirm(this.$i18n.gt('APPLICATION_CONFIRM_CANCEL_CREATE'))) {
                this.$router.push(`/locations/${this.locId}/cards/${this.cardId}/chart`)
            }
        },
        loadPestTarget() {
            this.pest = this.currentApplication.pestTarget
            for (let i = 0; i < this.pest.length; i++) {
                if (!_.find(this.cardsPestOptions, (option) => {return option.value === this.pest[i]})) {
                    this.otherPest = this.pest[i]
                    this.pest.splice(i, 1, 'OTHER')
                }
            }
        },
        updatePestTarget() {
            this.currentApplication.pestTarget = this.pest
            if (this.pest.includes('OTHER')) {
                this.currentApplication.pestTarget.splice(this.currentApplication.pestTarget.indexOf('OTHER'), 1, this.otherPest)
            }
        },
        loadDiseaseTarget() {
            this.disease = this.currentApplication.diseaseTarget
            for (let i = 0; i < this.disease.length; i++) {
                if (!_.find(this.cardsDiseaseOptions, (option) => {return option.value === this.disease[i]})) {
                    this.otherDisease = this.disease[i]
                    this.disease.splice(i, 1, 'OTHER')
                }
            }
        },
        updateDiseaseTarget() {
            this.currentApplication.diseaseTarget = this.disease
            if (this.disease.includes('OTHER')) {
                this.currentApplication.diseaseTarget.splice(this.currentApplication.diseaseTarget.indexOf('OTHER'), 1, this.otherDisease)
            }
        },
        formatDate(d) {
            return Utils.formatDate(d)
        },
    },
    async mounted() {
        const model = {options: []}
        await Card.loadCardOptionsGrouped(model)
        this.locationCardOptions = model.options
        if (this.cardId && this.locId) {
            if (this.applicationId) {
                this.currentApplication = await this.getApplication(this.locId, this.cardId, this.applicationId)
                this.currentApplication.cardsApplied = [`users/${this.userId}/locations/${this.locId}/cards/${this.cardId}`]
                await this.confirmSelection()
                // load pest target/disease target options, selections, other
                this.loadPestTarget()
                this.loadDiseaseTarget()
            } else {
                this.currentApplication = this.createApplication()
                this.currentApplication.cardsApplied = [`users/${this.userId}/locations/${this.locId}/cards/${this.cardId}`]
            }
        } else {
            this.currentApplication = this.createApplication()
        }
        this.cardLoaded = true
    }
}
</script>

<style lang="scss">
.applications-edit {

    .last-scout-table thead {
        display: none;
    }

    .disable-input {
        position: absolute;
        top: 0;
        left:15px;
        width: calc(100% - 30px);
        height: 100%;
        box-sizing: content-box;
        padding:10px 10px;
        margin:-10px -10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index: 1;
        //background: rgba(131, 231, 151,.9);
        background: rgba(226,214,202,.7);
    }
}
</style>


